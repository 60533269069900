<template>

  <b-card no-body>
    <div class="m-1">
      <b-row>
        <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
          <v-select v-model="perPage" class="per-page-selector d-inline-block mx-50" :options="perPageOptions" :clearable="false"/>
        </b-col>
        <b-col cols="12" md="6">
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Arama..."/>
            <header-table :new-route="{ name: 'idea-add' }" :re-fetch-data="reFetchData"></header-table>
          </div>
        </b-col>
      </b-row>
    </div>

    <b-table
        ref="refListTable"
        primary-key="id"
        empty-text="Kayıt bulunamadı"
        :class="`position-relative table-sticky ${totalCount > 0 && totalCount < 5 ? 'dropdown-h150': ''}`"
        :items="fetchList"
        :fields="tableColumns"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        responsive striped bordered hover show-empty>

      <template #cell(id)="data">
        <id-column-table :data="data.value" :route-name="'idea-edit'"></id-column-table>
      </template>

      <template #cell(createdOn)="data">
        <date-time-column-table :data="data.value"></date-time-column-table>
      </template>

      <template #cell(ideaTypeName)="data">
        <div class="text-nowrap">{{ data.value }}</div>
      </template>

      <template #cell(userProfilePicture)="data">
        <div class="text-nowrap">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                  size="32"
                  :src="getApiFile(data.value)"
                  :text="avatarText(data.item.userFullName)"
                  :variant="`light-primary`"/>
            </template>
            <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.userFullName }}
          </span>
            <small class="text-muted">{{ data.item.userPositionName }}</small>
          </b-media>
        </div>
      </template>

      <template #cell(ideaCategoryName)="data">
        <div class="text-nowrap">{{ data.value }}</div>
      </template>

      <template #cell(priorityStatusName)="data">
        <b-badge pill :variant="`light-${recordPriorityStatusVariant(data.item)}`" class="text-capitalize">
          {{ data.value }}
        </b-badge>
      </template>

      <template #cell(ideaStatusName)="data">
        <b-badge pill :variant="`light-${recordIdeaStatusVariant(data.item)}`" class="text-capitalize">
          {{ data.value }}
        </b-badge>
      </template>

      <template #cell(actions)="data">
        <div class="text-nowrap">
          <b-dropdown text="Dropdown" variant="link" no-caret toggle-class="p-0" dropleft>
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body"/>
            </template>
            <b-dropdown-item :to="{ name: 'idea-edit', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">Değiştir</span>
            </b-dropdown-item>
            <b-dropdown-item @click="cancelShowModal(data.item)" v-if="data.item.ideaStatusId === 'NEW'">
              <feather-icon icon="XSquareIcon"/>
              <span class="align-middle ml-50">İptal</span>
            </b-dropdown-item>
            <remove-button-table :data="data" :re-fetch-data="reFetchData" :dropdown="true"></remove-button-table>
          </b-dropdown>
          <feather-icon :id="`row-${data.item.id}-prev`" icon="EyeIcon" size="16" @click="previewRecord(data.item)" class="mx-1"/>
          <b-tooltip title="Görüntüle" class="cursor-pointer" :target="`row-${data.item.id}-prev`"/>
        </div>
      </template>

    </b-table>

    <div class="mx-2 mb-2">
      <b-row>
        <footer-table :data-meta="dataMeta"></footer-table>
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
          <b-pagination class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item" v-model="currentPage" :total-rows="totalCount" :per-page="perPage" first-number last-number>

            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18"/>
            </template>

            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18"/>
            </template>

          </b-pagination>
        </b-col>
      </b-row>
    </div>

    <b-modal v-model="cancelModalShow" ref="cancel-modal" title="İptal Et" ok-title="Kaydet" cancel-title="Vazgeç" cancel-variant="outline-secondary" @ok="handleCancelModal" centered no-close-on-backdrop no-close-on-esc>
      <b-form @submit.stop.prevent="submitCancelModal" ref="refFormObserver">
        <b-form-group label="* İptal Açıklaması">
          <b-form-textarea trim placeholder="Açıklama" v-model="cancelInfo.description" rows="3"/>
        </b-form-group>
      </b-form>
    </b-modal>

    <Overlay :busy="busy"></Overlay>
    <preview :is-preview-modal.sync="isPreviewModal" :data-record.sync="dataPreviewRecord"></preview>
  </b-card>

</template>

<script>
import {BCard, BRow, BCol, BFormInput, BTable, BPagination, BTooltip, BDropdown, BDropdownItem, BBadge, BMedia, BAvatar, BModal, BForm, BFormTextarea, BFormGroup} from 'bootstrap-vue'
import {FooterTable, RemoveButtonTable, EditButtonTable, DateTimeColumnTable, IdColumnTable, HeaderTable} from '@/components/Table';
import vSelect from 'vue-select'
import store from '@/store'
import storeModule from './store'
import Overlay from "@/components/Overlay.vue";
import Preview from "@/views/idea/idea/Preview.vue";
import {onUnmounted, ref} from '@vue/composition-api'
import useList from './useList'
import Vue from "vue";
import {avatarText, getApiFile, recordIdeaStatusVariant, recordPriorityStatusVariant} from "@core/utils/filter"

export default {
  components: {
    BFormGroup, BFormTextarea, BForm, BModal,
    BAvatar, BMedia,
    BBadge,
    BDropdownItem,
    BDropdown,
    BCard, BRow, BCol, BFormInput, BTable, BPagination, BTooltip,
    Overlay, Preview, HeaderTable, FooterTable, RemoveButtonTable, EditButtonTable, DateTimeColumnTable, IdColumnTable,
    vSelect,
  },
  setup() {
    const STORE_MODULE_NAME = 'store'
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const {
      tableColumns,
      perPage,
      currentPage,
      totalCount,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      busy,

      fetchList,
      reFetchData,
    } = useList()

    const dataPreviewRecord = ref({})
    const isPreviewModal = ref(false)
    const previewRecord = (dataItem) => {
      dataPreviewRecord.value = dataItem
      isPreviewModal.value = true
    }

    const cancelInfo = ref({
      ideaId: null,
      description: ''
    })
    const cancelModalShow = ref(false)
    const cancelShowModal = (dataItem) => {
      if (dataItem.ideaStatusId === 'NEW') {
        cancelInfo.value = {
          ideaId: dataItem.id,
          description: ''
        }

        cancelModalShow.value = true
      } else {
        Vue.swal({
          icon: 'error',
          title: 'Hata!',
          text: 'Durum uygun değil!',
          confirmButtonText: 'Tamam',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      }
    }
    const handleCancelModal = (bvModalEvt) => {
      bvModalEvt.preventDefault()
      submitCancelModal()
    }
    const submitCancelModal = () => {
      if (cancelInfo.value.description === null || cancelInfo.value.description === '') {
        Vue.swal({
          icon: 'error',
          title: 'Hata!',
          text: 'İptal açıklaması girilmelidir!',
          confirmButtonText: 'Tamam',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
        return
      }

      busy.value = true
      store.dispatch('store/cancelItem', cancelInfo.value).then(response => {
        reFetchData()
        Vue.swal({
          icon: 'success',
          title: 'Başarılı!',
          text: 'Kyaıt iptal edildi.',
          confirmButtonText: 'Tamam',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      }).catch(error => {
        Vue.swal({
          icon: 'error',
          title: 'Hata!',
          text: error.response.data.message,
          confirmButtonText: 'Tamam',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      }).finally(message => {
        busy.value = false
      })

      cancelInfo.value = {
        ideaId: null,
        description: ''
      }
      cancelModalShow.value = false
    }

    return {
      tableColumns,
      perPage,
      currentPage,
      totalCount,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      busy,
      isPreviewModal,
      dataPreviewRecord,
      cancelInfo,
      cancelModalShow,

      fetchList,
      reFetchData,
      previewRecord,
      avatarText,
      getApiFile,
      recordPriorityStatusVariant,
      recordIdeaStatusVariant,
      cancelShowModal,
      handleCancelModal,
      submitCancelModal,
    }
  }
}
</script>

<style scoped lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
