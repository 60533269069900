<template>
  <b-modal
      no-close-on-backdrop
      centered
      hide-header-close
      ok-variant="dark"
      title="Kayıt Bilgileri"
      modal-class="modal-dark"
      v-model="isPreviewModal"
      size="lg">

    <b-tabs pills>
      <b-tab active>
        <template #title>
          <feather-icon icon="MessageSquareIcon"/>
          <span>Geri Bildirim Bilgileri</span>
        </template>

        <table class="mt-2 mt-xl-0 w-100">
          <tr class="border-bottom">
            <th class="pb-50"><span class="font-weight-bold">Tarih</span></th>
            <td class="pb-50">
              <date-time-column-table :data="previewData.createdOn"></date-time-column-table>
            </td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Türü</span></th>
            <td class="py-50">{{ previewData.ideaTypeName }}</td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Kategori</span></th>
            <td class="py-50">{{ previewData.ideaCategoryName }}</td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Öncelik</span></th>
            <td class="py-50">{{ previewData.priorityStatusName }}</td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Açıklama</span></th>
            <td class="py-50">{{ previewData.description }}</td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Kayıt Sahibi</span></th>
            <td class="py-50">{{ previewData.userFullName }}</td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Durum</span></th>
            <td class="py-50">
              <b-badge pill :variant="`light-${recordIdeaStatusVariant(previewData)}`" class="text-capitalize">
                {{ previewData.ideaStatusName }}
              </b-badge>
            </td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Oluşturma Zamanı</span></th>
            <td class="py-50">
              <date-time-column-table :data="previewData.createdOn"></date-time-column-table>
            </td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Değiştirme Zamanı</span></th>
            <td class="py-50">
              <date-time-column-table :data="previewData.modifiedOn"></date-time-column-table>
            </td>
          </tr>
          <tr>
            <th class="pt-50"><span class="font-weight-bold">İptal Zamanı</span></th>
            <td class="pt-50">
              <date-time-column-table :data="previewData.cancelledOn"></date-time-column-table>
            </td>
          </tr>
        </table>

      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="FileIcon"/>
          <span>Döküman Bilgileri</span>
        </template>
        <b-card no-body class="border mt-1">
          <b-table
              :items="previewData.documents"
              :fields="documentFields"
              class="mb-0"
              striped
              responsive
              bordered
              hover>

            <template #cell(id)="data">
              <b-link class="font-weight-bold">#{{ data.value }}</b-link>
            </template>

            <template #cell(createdOn)="data">
              <date-time-column-table :data="data.value"></date-time-column-table>
            </template>

            <template #cell(name)="data">
              <div class="text-nowrap">{{ data.value }}</div>
            </template>

            <template #cell(fileType)="data">
              <div class="text-nowrap">{{ data.value }}</div>
            </template>

            <template #cell(fileSize)="data">
              <div class="text-nowrap">{{ data.value }}</div>
            </template>

            <template #cell(createdUserName)="data">
              <div class="text-nowrap">{{ data.value }}</div>
            </template>

            <template #cell(isCancelled)="data">
              <boolean-column-table :data="data.value"></boolean-column-table>
            </template>

            <template #cell(filePath)="data">
              <div class="text-nowrap">
                <feather-icon :id="`row-${data.item.id}-prev`" icon="EyeIcon" size="16" @click="previewFile(data.value)" class="mx-1"/>
              </div>
            </template>

          </b-table>
        </b-card>
      </b-tab>
      <b-tab v-if="actionVisible">
        <template #title>
          <feather-icon icon="MessageCircleIcon"/>
          <span>Aksiyon Bilgileri</span>
        </template>
        <b-card no-body class="border mt-1">
          <b-table
              :items="actionDataList"
              :fields="actionFields"
              class="mb-0"
              striped
              responsive
              bordered
              hover>

            <template #cell(createdOn)="data">
              <date-time-column-table :data="data.value"></date-time-column-table>
            </template>

            <template #cell(ideaStatusName)="data">
              <b-badge pill :variant="`light-${recordIdeaStatusVariant(data.item)}`" class="text-capitalize">
                {{ data.value }}
              </b-badge>
            </template>

          </b-table>
        </b-card>
      </b-tab>
    </b-tabs>

    <template #modal-footer="">
      <b-row class="d-flex justify-content-end align-items-center">
        <b-button class="mr-1" variant="outline-secondary" @click="closeModal">
          <feather-icon icon="XCircleIcon" size="16"/>
          <span class="align-middle" role="button"> Kapat</span>
        </b-button>
      </b-row>
    </template>

    <Overlay :busy="busy"></Overlay>
  </b-modal>
</template>

<script>
import {ref, watch} from '@vue/composition-api'
import {BBadge, BButton, BCard, BCol, BFormCheckbox, BFormGroup, BFormInput, BLink, BModal, BRow, BTab, BTable, BTabs} from "bootstrap-vue";
import {useToast} from "vue-toastification/composition";
import Overlay from "@/components/Overlay.vue";
import store from "@/store";
import {toastMessage} from "@core/utils/utils";
import {recordStatusVariant, recordStatusFormat, getApiFile, recordIdeaStatusVariant, recordPriorityStatusVariant} from "@core/utils/filter"
import {ActiveColumnTable, BooleanColumnTable, DateTimeColumnTable} from "@/components/Table";

export default {
  components: {
    ActiveColumnTable,
    DateTimeColumnTable,
    BooleanColumnTable,
    Overlay,

    BFormInput,
    BFormGroup,
    BCol,
    BFormCheckbox,
    BTable,
    BCard,
    BTab,
    BTabs,
    BRow,
    BButton,
    BModal,
    BBadge,
    BLink,
  },
  model: [
    {
      prop: 'isPreviewModal',
      event: 'update:is-preview-modal',
    },
    {
      prop: 'dataRecord',
      event: 'update:data-record',
    }
  ],
  props: {
    isPreviewModal: {
      type: Boolean,
      required: true,
    },
    dataRecord: {
      type: Object,
      required: true,
    },
  },
  setup(props, {emit}) {
    const toast = useToast()
    const busy = ref(false)
    const previewData = ref({});
    const actionDataList = ref([])
    const actionVisible = ref(true)

    watch(() => props.dataRecord, (dataValue) => {
      if (dataValue.id !== undefined && props.isPreviewModal) {
        busy.value = true
        store.dispatch('store/previewItem', {id: dataValue.id}).then(response => {
          previewData.value = response.data.data
        }).catch(error => {
          toastMessage(toast, 'danger', error.response.data.message)
          if (error.response.status === 403) {
            emit('update:is-preview-modal', false)
            emit('update:data-record', {})
          }
        }).finally(message => {
          busy.value = false
        })

        busy.value = true
        store.dispatch('store/fetchActionList', {ideaId: dataValue.id}).then(response => {
          actionDataList.value = response.data.data
        }).catch(error => {
          if (error.response.status === 403) {
            actionVisible.value = false
          } else {
            toastMessage(toast, 'danger', error.response.data.message)
          }
        }).finally(() => {
          busy.value = false
        })
      }
    })

    const actionFields = [
      {key: 'createdOn', label: 'Tarih'},
      {key: 'userFullName', label: 'Kullanıcı'},
      {key: 'description', label: 'Açıklama'},
      {key: 'ideaStatusName', label: 'Durum'},
    ]

    const documentFields = [
      {key: 'name', label: 'İsim', sortable: false},
      {key: 'createdUserName', label: 'Kayıt Sahibi', sortable: false},
      {key: 'createdOn', label: 'Tarih', sortable: true},
      {key: 'filePath', label: 'Görüntüle', sortable: false},
    ]

    const previewFile = (filePath) => {
      window.open(getApiFile(filePath), '_blank');
    }

    const closeModal = () => {
      emit('update:is-preview-modal', false)
      emit('update:data-record', {})
    }

    return {
      busy,
      previewData,
      actionFields,
      actionDataList,
      actionVisible,
      documentFields,

      recordIdeaStatusVariant,
      recordStatusFormat,
      recordStatusVariant,
      previewFile,
      closeModal
    }
  },
}
</script>
